export const colors = {
  tallyYellow: "#F4D85E",
  tallyYellowDark: "#cd9600",
  tallyLightGrey: "#74869a",
  tallyDarkGrey: "#2d353e",
  tallyGrey: "#3a4551",
  tallyGreyInset: "#404f5f",
  tallyBlack: "#383838",
  tallyGold: "#69582b",
  tallyWhite: "#fff",
  tallyBackground: "#3e4954",
  error: "#ff1744",
}

export const weight = {
  light: 400,
  medium: 500,
  heavy: 600,
}
